import {
  Block,
  Segment,
  Heading,
  Error,
  DataList,
  DataListItem,
  Column,
  Row,
  ColumnWidth,
  theme,
} from "@blueorigin/blue-branding-kit";
import { PageLoading } from "@blueorigin/blue-branding-kit/components/loading/page-loading";
import React from "react";
import { connect } from "react-redux";
import {
  setFilledAction,
  LayoutFilledActionPayload,
} from "../../../redux/layout/layout-actions";
import { setDisplayAction } from "../../../redux/display/display-actions";
import { DisplayState } from "../../../redux/display/display-state";
import { ActionCreator } from "typescript-fsa";
import { gql, QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  configureAttributes,
  configureBreadcrumbs,
} from "../page-header-manager";
import { PartInstance, WorkOrderRelationship } from "../../../models/generated";
import { Routes } from "../../../routes";
import styled from "styled-components";
import { DisplayComponent } from "../../../constants";

const Padding = styled.div`
  padding-top: ${theme("Dimensions.DoubleSpace")};
  padding-bottom: ${theme("Dimensions.DoubleSpace")};
`;

export const DetailsPageQuery = gql`
  query Q($instanceId: String) {
    partInstance(partInstanceId: $instanceId) {
      partInstanceId
      partName
      partNumber
      serialNumber
      lotNumber
      partVersion
      reviewId
      structure {
        partName
        partInstanceId
        serialNumber
        lotNumber
        partNumber
        displayName
        children {
          partName
        }
      }
      ancestry {
        partInstanceId
        partName
      }
      reports {
        name
        displayId
        reportType
        reportId
        workOrderRelationship
        instanceAttachmentTags
        foundNonConformanceCount
      }
    }
  }
`;

const ERROR_TITLE = "Part Not Found";
export interface DetailsPageProps {
  setFilledAction: ActionCreator<LayoutFilledActionPayload>;
  setDisplayAction: ActionCreator<DisplayState>;
  match?: any;
}
export interface Reports {
  EngineeringChange: DataListItem[];
  WaiverDeviation: DataListItem[];
  NonConformance: DataListItem[];
  WorkOrderInstance: DataListItem[];
  AcceptanceTestProcedure: DataListItem[];
  CertificateOfConformance: DataListItem[];
  DimensionsReport: DataListItem[];
  FirstArticleInspection: DataListItem[];
  IDPhoto: DataListItem[];
  InspectionReport: DataListItem[];
  LimitedLifeData: DataListItem[];
  MaterialCertification: DataListItem[];
  MaterialPropertyTest: DataListItem[];
  NDTReport: DataListItem[];
  ReceivingInspectionPackage: DataListItem[];
  TestDataHotFire: DataListItem[];
  TestDataOffEngine: DataListItem[];
  TestDataPreHotFireBuildVerification: DataListItem[];
}

export class DetailsPageComponent extends React.Component<DetailsPageProps> {
  public componentDidMount() {
    this.configureSearchBar();
  }

  public render() {
    const instanceId = _.get(
      this.props,
      ["match", "params", "instanceId"],
      null
    );
    const variables = { instanceId };
    return (
      <Query
        query={DetailsPageQuery}
        variables={variables}
        onCompleted={this.onCompleted}
      >
        {this.renderQuery}
      </Query>
    );
  }
  public renderQuery = ({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<{
    partInstance: PartInstance;
  }>) => {
    if (loading) {
      return <PageLoading />;
    }

    if (error || !data || !data.partInstance) {
      this.configureHeaderWithError();
      return (
        <Error data={error} tryAgain={refetch}>
          Could not load part details page
        </Error>
      );
    }

    let sortedSubAssembly = null;

    if (data.partInstance.structure) {
      // Create a clone of the structure array before sorting
      const clonedStructure = [...data.partInstance.structure];

      sortedSubAssembly = clonedStructure.sort((a, b) => {
        if (a.partNumber === null) return 1;
        if (b.partNumber === null) return -1;
        if (a.partNumber > b.partNumber) return 1;
        if (a.partNumber < b.partNumber) return -1;
        return 0; // if the part numbers are equal
      });
    }
    const structureItems = sortedSubAssembly
      ? _.map(data.partInstance.structure, (structure) => {
          return {
            title: (
              <>
                <strong>{structure.partName}</strong>
                {Boolean(structure.partNumber) && (
                  <span style={{ color: "black", paddingLeft: "1rem" }}>
                    <strong>P/N</strong> {structure.partNumber}
                  </span>
                )}
                {Boolean(structure.serialNumber) && (
                  <span style={{ color: "black", paddingLeft: "1rem" }}>
                    <strong>S/N</strong> {structure.serialNumber}
                  </span>
                )}
                {Boolean(structure.lotNumber) && (
                  <span style={{ color: "black", paddingLeft: "1rem" }}>
                    <strong>L/N</strong> {structure.lotNumber}
                  </span>
                )}
                {Boolean(structure.children) && (
                  <span style={{ color: "black", paddingLeft: "1rem" }}>
                    <br></br>
                    {structure.children?.length}{" "}
                    <strong>Sub-Assembly Parts Found</strong>
                  </span>
                )}
              </>
            ),
            url: Routes.partInstance(structure.partInstanceId),
          };
        })
      : [];

    const reports: Reports = this.groupReports(data);

    const attachmentTypes = {
      NonConformance: {
        items: reports.NonConformance,
        title: "Non-conformances",
        icon: "fas fa-exclamation-circle",
      },
      AcceptanceTestProcedure: {
        items: reports.AcceptanceTestProcedure,
        title: "Acceptance Test Procedure",
        icon: "fas fa-regular fa-check",
      },
      CertificateOfConformance: {
        items: reports.CertificateOfConformance,
        title: "Certificate of Conformance",
        icon: "fas fa-regular fa-certificate",
      },
      DimensionsReport: {
        items: reports.DimensionsReport,
        title: "Dimensions Report",
        icon: "fas fa-regular fa-ruler-combined",
      },
      FirstArticleInspection: {
        items: reports.FirstArticleInspection,
        title: "First Article Inspection",
        icon: "fas fa-clipboard-check",
      },
      IDPhoto: {
        items: reports.IDPhoto,
        title: "ID Photo",
        icon: "fas fa-solid fa-id-badge",
      },
      InspectionReport: {
        items: reports.InspectionReport,
        title: "Inspection Report",
        icon: "fas fa-solid fa-file-contract",
      },
      LimitedLifeData: {
        items: reports.LimitedLifeData,
        title: "Limited Life Data",
        icon: "fas fa-solid fa-clock",
      },
      MaterialCertification: {
        items: reports.MaterialCertification,
        title: "Material Certifications",
        icon: "fas fa-solid fa-money-check",
      },
      MaterialPropertyTest: {
        items: reports.MaterialPropertyTest,
        title: "Material Property Test",
        icon: "fas fa-solid fa-bars",
      },
      NDTReport: {
        items: reports.NDTReport,
        title: "NDT Report",
        icon: "fas fa-solid fa-clipboard",
      },
      ReceivingInspectionPackage: {
        items: reports.ReceivingInspectionPackage,
        title: "Receiving Inspection Package",
        icon: "fas fa-solid fa-box",
      },
      TestDataHotFire: {
        items: reports.TestDataHotFire,
        title: "Test Data: Hot Fire",
        icon: "fas fa-solid fa-fire",
      },
      TestDataOffEngine: {
        items: reports.TestDataOffEngine,
        title: "Test Data: Off Engine",
        icon: "fas fa-solid fa-power-off",
      },
      TestDataPreHotFireBuildVerification: {
        items: reports.TestDataPreHotFireBuildVerification,
        title: "Test Data: Pre-Hot Fire Build Verification",
        icon: "fas fa-solid fa-cubes",
      },
    };

    const qualityDataResult = this.qualityDataBuilder(reports, attachmentTypes);

    const qualityData = _.map(qualityDataResult, (rows, index) =>
      rows.length === 2 ? (
        <Row key={index}>
          <Column width={ColumnWidth.fillWidth}>
            <DataList
              items={rows[0].items}
              displayedNumberOfItems={3}
              title={rows[0].title}
              icon={rows[0].icon}
            />
          </Column>
          <Column width={ColumnWidth.fillWidth}>
            <DataList
              items={rows[1].items}
              displayedNumberOfItems={3}
              title={rows[1].title}
              icon={rows[1].icon}
            />
          </Column>
        </Row>
      ) : (
        <Row key={index}>
          <Column width={ColumnWidth.fillWidth}>
            <DataList
              items={rows[0].items}
              displayedNumberOfItems={3}
              title={rows[0].title}
              icon={rows[0].icon}
            />
          </Column>
        </Row>
      )
    );

    if (qualityDataResult.length === 0) {
      return (
        <React.Fragment>
          <Block>
            <Segment>
              <Heading>Structure</Heading>
              <DataList items={structureItems} />
            </Segment>
          </Block>
          <Block>
            <Segment>
              <Heading>Quality</Heading>
              <Column style={{ paddingTop: "1.25rem" }}>
                <i>This part does not have any pedigree data available.</i>
              </Column>
            </Segment>
          </Block>
          <Block>
            <Segment>
              <Heading>Manufacturing</Heading>
              <Padding>
                <Column width={ColumnWidth.fillWidth}>
                  <DataList
                    items={reports.WorkOrderInstance}
                    displayedNumberOfItems={3}
                    title="Work Orders"
                    icon="fas fa-clipboard-list"
                    emptyStateMessage="This part does not have any work orders."
                  />
                </Column>
              </Padding>
            </Segment>
          </Block>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Block>
          <Segment>
            <Heading>Structure</Heading>
            <DataList items={structureItems} />
          </Segment>
        </Block>
        <Block>
          <Segment>
            <Heading>Quality</Heading>
            <Padding>{qualityData}</Padding>
          </Segment>
        </Block>
        <Block>
          <Segment>
            <Heading>Manufacturing</Heading>
            <Padding>
              <Column width={ColumnWidth.fillWidth}>
                <DataList
                  items={reports.WorkOrderInstance}
                  displayedNumberOfItems={3}
                  title="Work Orders"
                  icon="fas fa-clipboard-list"
                  emptyStateMessage="This part does not have any work orders."
                />
              </Column>
            </Padding>
          </Segment>
        </Block>
      </React.Fragment>
    );
  };

  private groupReports = (data: { partInstance: PartInstance }) => {
    var { reports, partInstanceId } = data.partInstance;

    // Sort work orders by relationship: Produce > Consume > Maintain
    const sortOrder = Object.values(WorkOrderRelationship);
    reports = _.sortBy(reports, (report) => {
      return report.workOrderRelationship
        ? sortOrder.indexOf(report.workOrderRelationship)
        : 0;
    });

    let groupedReports = _.reduce(
      reports,
      (result: any, report) => {
        result[report.reportType] || (result[report.reportType] = []);
        if (report.reportType === "EngineeringChange") {
          result[report.reportType].push({
            title: (
              <>
                <strong>{report.displayId}:</strong> {report.name}
              </>
            ),
            url: Routes.engineeringChange(report.reportId, partInstanceId),
          });
        } else if (report.reportType === "WaiverDeviation") {
          result[report.reportType].push({
            title: report.name,
            url: Routes.file(report.reportId, partInstanceId),
          });
        } else if (report.reportType === "WorkOrderInstance") {
          result[report.reportType].push({
            title: (
              <>
                <strong>WO-{report.displayId}:</strong> {report.name}
                {Boolean(report.foundNonConformanceCount) &&
                  Number(report.foundNonConformanceCount) > 0 && (
                    <span style={{ color: "black", paddingLeft: "1rem" }}>
                      {report.foundNonConformanceCount} NC Found
                    </span>
                  )}
              </>
            ),
            url: Routes.workOrder(report.reportId, partInstanceId),
            info: report.workOrderRelationship
              ? ["Relationship: " + report.workOrderRelationship]
              : [],
          });
        } else if (report.reportType === "NonConformance") {
          result[report.reportType].push({
            title: (
              <>
                <strong>NC-{report.displayId}:</strong> {report.name}
              </>
            ),
            url: Routes.nonconformance(report.reportId, partInstanceId),
          });
        } else if (report.reportType === "InstanceAttachment") {
          if (report.instanceAttachmentTags) {
            for (var i = 0; i < report.instanceAttachmentTags.length; i++) {
              if (result[report.instanceAttachmentTags[i]]) {
                result[report.instanceAttachmentTags[i]].push({
                  title: report.name,
                  url: Routes.file(report.reportId, partInstanceId),
                });
              } else {
                result[report.instanceAttachmentTags[i]] = [
                  {
                    title: report.name,
                    url: Routes.file(report.reportId, partInstanceId),
                  },
                ];
              }
            }
          }
        } else {
          console.warn(
            `The report type of ${report.reportType} is not a valid report type.`
          );
        }
        return result;
      },
      {}
    );
    return groupedReports;
  };

  private qualityDataBuilder = (
    reports: Reports,
    attachmentTypes: any
  ): Array<Array<any>> => {
    const reportMapKeys = Object.keys(Object(reports));

    var qualityData = [];
    var rowData: Object[] = [];

    for (var k = 0; k < reportMapKeys.length; k++) {
      if (rowData.length === 2) {
        qualityData.push(rowData);
        rowData = [];
      }

      if (attachmentTypes[reportMapKeys[k]]) {
        rowData.push(attachmentTypes[reportMapKeys[k]]);
      }
    }

    if (rowData.length > 0) {
      qualityData.push(rowData);
    }

    return qualityData;
  };

  public configureSearchBar = () => {
    this.props.setDisplayAction({
      payload: DisplayComponent.searchBar,
    });
  };

  public onCompleted = (data: any) => {
    if (data && data.partInstance) {
      const { partInstance } = data;
      this.props.setFilledAction({
        title: partInstance.partName,
        attributes: configureAttributes(partInstance),
        breadcrumbs: configureBreadcrumbs(partInstance.ancestry),
      });
    }
  };

  public configureHeaderWithError = () => {
    this.props.setFilledAction({
      title: ERROR_TITLE,
      breadcrumbs: configureBreadcrumbs(),
    });
  };
}

export const DetailsPage = connect(null, { setFilledAction, setDisplayAction })(
  withRouter<any, any>(DetailsPageComponent as any) as any
);
