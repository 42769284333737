import { useState, useEffect } from "react";
import {
  AnchorLinkUndecorated,
  CloseButton,
  FileThumb,
} from "@blueorigin/blue-branding-kit";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { appConfig } from "../config";

const handleDownload = async (fileName: string, url: string) => {
  console.debug("fileName", fileName);
  const urlObject = new URL(url);
  const s3Path = urlObject.pathname.split("/").slice(1).join("/");
  console.debug("s3 path", s3Path);
  const encodedS3Path = s3Path.replace("/", "%2F");
  console.debug("s3 path, encoded", encodedS3Path);
  const s3ProxyUrl = `${appConfig.s3ProxyUri}/get/${encodedS3Path}`;

  const oktaTokenStorage = JSON.parse(
    localStorage.getItem("okta-token-storage") || "{}"
  );
  const idToken = oktaTokenStorage?.idToken?.value;

  if (!idToken) {
    console.error("No ID token found");
    return;
  }

  const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"];
  const isImageFile = imageExtensions.some((ext) =>
    fileName.toLowerCase().endsWith(ext)
  );

  try {
    const response = await fetch(s3ProxyUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();

      if (isImageFile) {
        // Ensure the blob has an appropriate mime type for its extension
        let blobType = blob.type;
        if (isImageFile && !imageExtensions.includes(blobType)) {
          const ext = imageExtensions.find((ext) =>
            fileName.toLowerCase().endsWith(ext)
          );
          blobType = `image/${ext?.replace(".", "")}`;
        }
        const newBlob = new Blob([blob], { type: blobType });
        return window.URL.createObjectURL(newBlob);
      } else {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } else {
      throw new Error("File download failed");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

interface FileAttachmentProps {
  url: string;
  fileName: string;
}

export function FileAttachment({ url, fileName }: FileAttachmentProps) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleClick = async () => {
    try {
      setOpen(true);
      const result = await handleDownload(fileName, url);
      if (result && result.startsWith("blob:")) {
        setImageSrc(result);
      } else {
        setError(false);
      }
      setOpen(false);
    } catch (error) {
      console.error("Error downloading or embedding file", error);
      setError(true);
    }
  };

  useEffect(() => {
    return () => {
      if (imageSrc) {
        window.URL.revokeObjectURL(imageSrc);
      }
    };
  }, [imageSrc]);

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {imageSrc ? (
        <AnchorLinkUndecorated
          key={url}
          href={imageSrc}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FileThumb
            file={{
              name: fileName,
              url: imageSrc,
            }}
          />
        </AnchorLinkUndecorated>
      ) : (
        <AnchorLinkUndecorated
          key={url}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FileThumb
            file={{
              name: fileName,
              url: "/attachment.png",
            }}
          />
        </AnchorLinkUndecorated>
      )}
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={6000}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseButton />
          </IconButton>
        }
      >
        {error ? (
          <SnackbarContent
            style={{ backgroundColor: red[600] }}
            message="There was an error downloading the file."
          />
        ) : (
          <SnackbarContent message="Preparing download..." />
        )}
      </Snackbar>
    </>
  );
}
