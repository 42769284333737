/* eslint-disable no-useless-escape */
// Storing functions that facilitate the exporting of data
import * as React from "react";
import {
  DispositionStatus,
  Nonconformance,
  OperationStatus,
  Skippability,
  StepStatus,
  WorkOrderInstance,
} from "../../models/generated";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";
import { List, Segment } from "@blueorigin/blue-branding-kit";
import moment from "moment";
import { formatRole } from "../layout/sign-off";
import { formatType } from "./nonconformance/nonconformance-problem-sources";
import { Routes } from "../../routes";
import { Html } from "react-pdf-html";
import { formatStatus } from "./work-orders/work-order-page";
import { FileAttachment } from "../FileAttachment";

Font.register({
  family: "Barlow",
  format: "truetype",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPCw.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4c4A.ttf",
      fontWeight: "bold",
    },
    {
      src:
        "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOA5Vop.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    {
      src: "https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Ccs8.ttf",
      fontStyle: "italic",
    },
  ],
});

export const styles = StyleSheet.create({
  coverPageTitle: {
    fontFamily: "Barlow",
    fontSize: "56",
    fontWeight: "bold",
    color: "#0033DD",
    textAlign: "center",
    marginTop: "100",
    marginBottom: "75",
  },
  headerLogo: {
    fontFamily: "Barlow",
    fontSize: "20",
    fontWeight: "bold",
    color: "#0033DD",
    marginBottom: "10",
  },
  headerText: {
    fontFamily: "Barlow",
    fontSize: "12",
    marginTop: "10",
    textAlign: "right",
  },
  footer: {
    bottom: "30",
    position: "absolute",
    right: "60",
    left: "60",
  },
  title: {
    fontFamily: "Barlow",
    fontSize: "20",
    textAlign: "center",
    marginBottom: "50",
    marginLeft: "20",
    marginRight: "20",
  },
  text: {
    fontFamily: "Barlow",
    fontSize: "12",
    marginTop: "10",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  textBold: {
    fontFamily: "Barlow",
    fontSize: "12",
    fontWeight: "bold",
    marginTop: "10",
  },
  link: {
    fontFamily: "Barlow",
    fontSize: "12",
    fontWeight: "bold",
    marginTop: "10",
    color: "#0033DD",
    textDecoration: "none",
  },
  textCentered: {
    fontFamily: "Barlow",
    fontSize: "12",
    textAlign: "center",
    marginTop: "20",
  },
  coverPageEmphasized: {
    fontFamily: "Barlow",
    fontSize: "12",
    textAlign: "center",
    fontWeight: "bold",
    marginLeft: "20",
    marginRight: "20",
    marginTop: "10",
  },
  h1: {
    fontFamily: "Barlow",
    fontSize: "20",
    fontWeight: "bold",
  },
  h2: {
    fontFamily: "Barlow",
    fontSize: "16",
    fontWeight: "bold",
    marginTop: "10",
  },
  h3: {
    fontFamily: "Barlow",
    fontSize: "14",
    fontWeight: "bold",
    marginTop: "10",
  },
  h4: {
    fontFamily: "Barlow",
    fontSize: "13",
    fontWeight: "bold",
    marginTop: "10",
  },
  line: {
    border: "0.5 solid black",
    marginBottom: "5",
  },
  lineGrey: {
    border: "0.5 solid #E2E2E2",
    marginBottom: "10",
    marginTop: "20",
  },
  lineDarkGrey: {
    border: "0.5 solid #a3a3a3",
    marginBottom: "10",
    marginTop: "20",
  },
  lineBlue: {
    border: "0.5 solid #0033DD",
    marginBottom: "10",
    marginTop: "20",
  },
  page: {
    width: "80",
    paddingHorizontal: "60",
    paddingTop: "35",
    paddingBottom: "65",
  },
  p: {
    fontFamily: "Barlow",
    fontSize: "12",
    marginTop: "10",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  strong: {
    fontFamily: "Barlow",
    fontSize: "12",
    fontWeight: "bold",
    marginTop: "10",
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    height: "auto",
  },
  td: {
    fontFamily: "Barlow",
    fontSize: "12",
    height: "auto",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  tr: {
    height: "auto",
  },
  tbody: {
    display: "flex",
    flexDirection: "column",
  },
  li: {
    fontFamily: "Barlow",
    fontSize: "12",
    paddingTop: "5",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  ul: {
    display: "flex",
    margin: 0,
    padding: 0,
  },
  div: {
    fontFamily: "Barlow",
    fontSize: "12",
    marginTop: "5",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

/**
 * Builds a document for non-conformances. Processes the data and displays only if the field is not null.
 * @param nonconformance: Nonconformance object
 * @returns a document containing NC data
 */
export const ExportedNC = ({ nonconformance }: Nonconformance | any) => {
  const {
    reportId,
    rejectCode,
    rejectCategory,
    summary,
    defectiveParts,
    causeAssessment,
    dispositions,
    problemSources,
    containment,
  } = nonconformance;

  const completedDispositions = dispositions?.filter(
    (disposition: { status: DispositionStatus }) =>
      disposition.status === DispositionStatus.Closed
  );

  return (
    <Document>
      <Page style={styles.page}>
        <View
          fixed
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text style={styles.headerLogo}>BLUE ORIGIN </Text>
          <Text style={styles.headerText}>{reportId} </Text>
        </View>
        <hr style={styles.line}></hr>
        <View>
          <Text style={styles.coverPageTitle}>BLUE ORIGIN</Text>
          <Text style={styles.title}>{reportId}</Text>
          {rejectCategory && (
            <Text style={styles.title}>
              {rejectCategory} ({rejectCode})
            </Text>
          )}
        </View>
        <View style={{ marginTop: "20" }}>
          <Text style={styles.coverPageEmphasized}>
            RESTRICTED– Blue Origin, Proprietary Information –{" "}
          </Text>
          <Text style={styles.coverPageEmphasized}>
            Access and distribution limited to Blue Origin and Trusted Partners
          </Text>
          <Text style={styles.textCentered}>
            INFORMATION SUBJECT TO EXPORT CONTROL LAWS
          </Text>
          <Text style={styles.text}>
            This document and any attachments hereto may contain information
            subject to United States export control laws, and which may not be
            exported from the United States, or otherwise released or disclosed
            to foreign nationals inside or outside the United States, without
            first obtaining an export license from the United States Government
            under the International Traffic in Arms Regulations (ITAR) and/or
            the Export Administration Regulations (EAR). Violation of United
            States export control laws is subject to imprisonment and/or fine.
          </Text>
        </View>
        <View style={styles.footer} fixed>
          <hr style={styles.line}></hr>
          <Text
            style={styles.text}
            render={({ pageNumber }) =>
              `Restricted - Blue Origin Proprietary Information / Subject to Export Control                                ${pageNumber}`
            }
          />
        </View>
      </Page>
      <Page style={styles.page}>
        <View
          fixed
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text style={styles.headerLogo}>BLUE ORIGIN </Text>
          <Text style={styles.headerText}>{reportId} </Text>
        </View>
        <View fixed>
          <hr style={styles.line}></hr>
        </View>
        {summary && (
          <View>
            <Text style={styles.h1}>Summary</Text>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={styles.text}>Status: </Text>
              <Text style={styles.textBold}>
                {formatType(summary.status) || "Not Available"}
              </Text>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={styles.text}>Created: </Text>
              <Text style={styles.textBold}>
                {moment.unix(summary.createdOn).format("MM/DD/YYYY") ||
                  "Not Available"}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={styles.text}>Closed: </Text>
              <Text style={styles.textBold}>
                {moment.unix(summary.closedOn).format("MM/DD/YYYY") ||
                  "Not Available"}
              </Text>
            </View>
            {completedDispositions &&
              completedDispositions.length > 0 &&
              completedDispositions.map((disposition: any, index: any) => (
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <Text style={styles.text}>
                    {index === 0
                      ? "Active Disposition:"
                      : "Disposition complete:"}{" "}
                  </Text>
                  <Text style={styles.textBold}>
                    {formatType(disposition.type) || "Unknown"}
                    {" | Closed"}
                    {index > 0 ? " - SUPERSEDED" : ""}
                  </Text>
                </View>
              ))}
            <Text style={styles.h2}>Initial condition</Text>
            <Text style={styles.text}>
              {summary.initialCondition || "Not Available"}
            </Text>
            <Text style={styles.h2}>Should be condition</Text>
            <Text style={styles.text}>
              {summary.shouldBeCondition || "Not Available"}
            </Text>
            <Text style={styles.h2}>When to resolve by</Text>
            <Text style={styles.text}>
              {summary.resolveBy || "Not Available"}
            </Text>
            {summary.attachments && summary.attachments.length > 0 && (
              <Segment>
                {summary.attachments.map(({ fileName, url }: any) => (
                  <FileAttachment url={url} fileName={fileName} />
                ))}
              </Segment>
            )}
            <hr style={styles.lineGrey}></hr>
          </View>
        )}
        <View>
          <Text style={styles.h1}>Defective Parts</Text>
          {defectiveParts && defectiveParts.length > 0 ? (
            <List>
              {defectiveParts.map((defectivePart: any) => (
                <View
                  style={{ flexDirection: "row", flexWrap: "wrap" }}
                  wrap={false}
                >
                  <Text style={styles.textBold}>{defectivePart.partName} </Text>
                  <span></span>
                  <Text style={styles.text}> P/N </Text>
                  <Text style={styles.textBold}>
                    {" "}
                    {defectivePart.partNumber}{" "}
                  </Text>
                  <span></span>
                  <Text style={styles.text}> L/N </Text>
                  <Text style={styles.textBold}>
                    {" "}
                    {defectivePart.lotNumber}{" "}
                  </Text>
                  <span></span> <Text style={styles.text}> S/N </Text>
                  <Text style={styles.textBold}>
                    {" "}
                    {defectivePart.serialNumber}{" "}
                  </Text>
                </View>
              ))}
            </List>
          ) : (
            <Text style={styles.text}>No defective parts</Text>
          )}
          <hr style={styles.lineGrey}></hr>
        </View>
        <View wrap={false}>
          <Text style={styles.h1}>Problem Sources</Text>
          {problemSources && problemSources.length > 0 ? (
            <List>
              {problemSources.map((problemSource: any) => (
                <View
                  style={{ flexDirection: "row", flexWrap: "wrap" }}
                  wrap={false}
                >
                  <Text style={styles.text}>
                    {formatType(problemSource.type)}{" "}
                  </Text>
                  {formatType(problemSource.type) !== problemSource.name && (
                    <div>
                      <span></span>
                      <Text style={styles.textBold}>{problemSource.name}</Text>
                    </div>
                  )}
                </View>
              ))}
            </List>
          ) : (
            <Text style={styles.text}>No problem sources</Text>
          )}
          <hr style={styles.lineGrey}></hr>
        </View>
        <View wrap={false}>
          <Text style={styles.h1}>Cause Assessment</Text>
          {causeAssessment &&
          (causeAssessment.cause || causeAssessment.supportingEvidence) ? (
            <>
              {causeAssessment.cause && (
                <>
                  <Text style={styles.h2}>
                    What caused this non-conformance?
                  </Text>
                  <Text style={styles.text}>{causeAssessment.cause}</Text>
                </>
              )}
              {causeAssessment.supportingEvidence && (
                <>
                  <Text style={styles.h2}>Supporting evidence</Text>
                  <Text style={styles.text}>
                    {causeAssessment.supportingEvidence}
                  </Text>
                </>
              )}
            </>
          ) : (
            <Text style={styles.text}>No cause assessment</Text>
          )}
          <hr style={styles.lineGrey}></hr>
        </View>
        <View wrap={false}>
          <Text style={styles.h1}>Containment</Text>
          {containment &&
          (containment.containmentRequired != null ||
            (containment.whereFound && containment.whereFound.length > 0)) ? (
            <>
              {containment.containmentRequired != null && (
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <Text style={styles.text}>Containment / stock check is:</Text>
                  <span></span>
                  <Text style={styles.textBold}>
                    {containment.containmentRequired
                      ? " Required"
                      : " Not required"}
                  </Text>
                </View>
              )}
              {containment.whereFound && containment.whereFound.length > 0 && (
                <Text style={styles.text}>
                  Tickets:
                  {containment.whereFound.map((item: any) => (
                    <View>
                      <Text key={item} style={styles.text}>
                        {" "}
                        {item}
                      </Text>
                    </View>
                  ))}
                </Text>
              )}
            </>
          ) : (
            <Text style={styles.text}>No containment data</Text>
          )}
          <hr style={styles.lineGrey}></hr>
        </View>
        <View>
          <Text style={styles.h1}>Dispositions</Text>
          {dispositions && dispositions.length > 0 ? (
            dispositions.map((disposition: any) => (
              <View>
                <Text style={styles.h2}>{formatType(disposition.type)}</Text>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <Text style={styles.text}>Status: </Text>
                  <Text style={styles.textBold}>
                    {formatType(disposition.status) || "Not Available"}
                  </Text>
                </View>
                <Text style={styles.h3}>Deviation assessment</Text>
                <Text style={styles.text}>
                  {disposition.assessment ||
                    "No factors were adversely affected"}
                </Text>
                {disposition.rationale && (
                  <View>
                    <Text style={styles.h3}>Disposition rationale</Text>
                    <Text style={styles.text}>{disposition.rationale}</Text>
                  </View>
                )}
                {disposition.notes && (
                  <View>
                    <Text style={styles.h3}>Disposition notes</Text>
                    <Text style={styles.text}>{disposition.notes}</Text>
                  </View>
                )}
                {disposition.engineeringAnalysis && (
                  <View>
                    <Text style={styles.h3}>Engineering analysis</Text>
                    <Text style={styles.text}>
                      {disposition.engineeringAnalysis}
                    </Text>
                  </View>
                )}
                {disposition.nonconformanceWorkOrders &&
                  disposition.nonconformanceWorkOrders.length > 0 && (
                    <View>
                      <Text style={styles.h3}>Work orders</Text>
                      {disposition.nonconformanceWorkOrders.map(
                        (workOrder: any) => (
                          <View>
                            {workOrder.id ? (
                              <Link
                                style={styles.link}
                                src={
                                  window.location.origin +
                                  Routes.workOrder(workOrder.id)
                                }
                              >
                                WO-{workOrder.displayId}
                              </Link>
                            ) : (
                              <Text style={styles.textBold}>
                                WO-{workOrder.displayId}
                              </Text>
                            )}
                          </View>
                        )
                      )}
                    </View>
                  )}

                {disposition.signOffs && disposition.signOffs.length > 0 && (
                  <>
                    {disposition.signOffs.map((signOff: any) => (
                      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                        <Text style={styles.text}>Sign-off </Text>
                        <Text style={styles.textBold}> {signOff.role} </Text>
                        <Text style={styles.text}>
                          {" "}
                          {moment.unix(signOff.date).format("MM/DD/YYYY")}
                        </Text>
                      </View>
                    ))}
                  </>
                )}
                <hr style={styles.lineGrey}></hr>
              </View>
            ))
          ) : (
            <Text style={styles.text}>No dispositions</Text>
          )}
          <hr style={styles.lineGrey}></hr>
        </View>
        <View style={styles.footer} fixed>
          <hr style={styles.line}></hr>
          <Text
            style={styles.text}
            render={({ pageNumber }) =>
              `Restricted - Blue Origin Proprietary Information / Subject to Export Control                                ${pageNumber}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

/**
 * Builds a document for work orders. Processes the data and displays only if the field is not null.
 * @param workOrder: WorkOrderInstance object
 * @returns a document containing WO data
 */
export const ExportedWO = ({ workOrder }: WorkOrderInstance | any) => {
  const {
    summary,
    status,
    title,
    billOfMaterials,
    operations,
    displayId,
  } = workOrder;

  const displayedOperations = operations?.filter(
    (operation: any) =>
      operation.status !== OperationStatus.Deleted &&
      operation.title !== "Deleted"
  );

  const deletedOperations = operations?.filter(
    (operation: any) => operation.title === "Deleted"
  );

  return (
    <Document>
      <Page style={styles.page}>
        <View
          fixed
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text style={styles.headerLogo}>BLUE ORIGIN </Text>
          <Text style={styles.headerText}>WO-{displayId}</Text>
        </View>
        <hr style={styles.line}></hr>
        <View>
          <Text style={styles.coverPageTitle}>BLUE ORIGIN</Text>
          <Text style={styles.title}>WO-{displayId}</Text>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={{ marginTop: "20" }}>
          <Text style={styles.coverPageEmphasized}>
            RESTRICTED– Blue Origin, Proprietary Information –{" "}
          </Text>
          <Text style={styles.coverPageEmphasized}>
            Access and distribution limited to Blue Origin and Trusted Partners
          </Text>
          <Text style={styles.textCentered}>
            INFORMATION SUBJECT TO EXPORT CONTROL LAWS
          </Text>
          <Text style={styles.text}>
            This document and any attachments hereto may contain information
            subject to United States export control laws, and which may not be
            exported from the United States, or otherwise released or disclosed
            to foreign nationals inside or outside the United States, without
            first obtaining an export license from the United States Government
            under the International Traffic in Arms Regulations (ITAR) and/or
            the Export Administration Regulations (EAR). Violation of United
            States export control laws is subject to imprisonment and/or fine.
          </Text>
        </View>
        <View style={styles.footer} fixed>
          <hr style={styles.line}></hr>
          <Text
            style={styles.text}
            render={({ pageNumber }) =>
              `Restricted - Blue Origin Proprietary Information / Subject to Export Control                                ${pageNumber}`
            }
          />
        </View>
      </Page>
      <Page style={styles.page}>
        <View
          fixed
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text style={styles.headerLogo}>BLUE ORIGIN </Text>
          <Text style={styles.headerText}>WO-{displayId}</Text>
        </View>
        <View fixed>
          <hr style={styles.line}></hr>
        </View>
        {summary ? (
          <View>
            <Text style={styles.h1}>Summary</Text>
            <Text style={styles.text}>
              Status:{" "}
              <Text style={styles.textBold}>{formatStatus(status)}</Text>
            </Text>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={styles.text}>Quantity </Text>
              <Text style={styles.textBold}>{summary.quantity}</Text>
              <Text style={styles.text}> of </Text>
              <Text style={styles.textBold}>{summary.partName} </Text>
              <Text style={styles.text}>P/N </Text>
              <Text style={styles.textBold}>{summary.partNumber} </Text>
              <Text style={styles.text}> Ver.</Text>
              <Text style={styles.textBold}>{summary.version}</Text>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={styles.text}>Started: </Text>
              <Text style={styles.textBold}>
                {moment.unix(summary.startTimestamp).format("MM/DD/YYYY") ||
                  "Not Available"}{" "}
              </Text>
            </View>
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <Text style={styles.text}>Completed: </Text>
              <Text style={styles.textBold}>
                {moment.unix(summary.completedTimestamp).format("MM/DD/YYYY") ||
                  "Not Available"}{" "}
                {summary.lotNumbers && (
                  <>
                    {summary.lotNumbers
                      .map((lotNumber: any) => `Lot ${lotNumber}`)
                      .join(", ")}
                  </>
                )}
              </Text>
            </View>
            {summary.notes && (
              <View>
                <Html stylesheet={styles}>
                  {formatJulesContent(summary.notes)}
                </Html>
              </View>
            )}
            {summary.attachments && summary.attachments.length > 0 && (
              <Segment>
                {summary.attachments.map(({ fileName, url }: any) => (
                  <FileAttachment url={url} fileName={fileName} />
                ))}
              </Segment>
            )}
            <hr style={styles.lineGrey}></hr>
          </View>
        ) : (
          <Text style={styles.text}>No summary available</Text>
        )}

        <View>
          <Text style={styles.h1}>Bill of Materials</Text>
          {billOfMaterials && billOfMaterials.length > 0 ? (
            <List>
              {billOfMaterials.map((bom: any) => (
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingBottom: "5px",
                  }}
                  wrap={false}
                >
                  {bom.partInstanceId ? (
                    <Link
                      style={styles.link}
                      src={
                        window.location.origin +
                        Routes.partInstance(bom.partInstanceId)
                      }
                    >
                      {bom.partName}{" "}
                    </Link>
                  ) : (
                    <Text style={styles.textBold}>{bom.partName} </Text>
                  )}
                  <span></span>
                  <Text style={styles.text}> Action </Text>
                  <Text style={styles.textBold}> {bom.action} </Text>
                  <span></span>
                  <Text style={styles.text}> P/N </Text>
                  <Text style={styles.textBold}> {bom.partNumber} </Text>
                  <span></span>
                  <Text style={styles.text}> Ver. </Text>
                  <Text style={styles.textBold}> {bom.version} </Text>
                  <span></span>
                  <Text style={styles.text}> Quantity </Text>
                  <Text style={styles.textBold}> {bom.quantity} </Text>
                </View>
              ))}
            </List>
          ) : (
            <Text style={styles.text}>No bill of materials</Text>
          )}
          <hr style={styles.lineGrey}></hr>
        </View>
        <View>
          <Text style={styles.h1}>Operations</Text>
          {displayedOperations && displayedOperations.length > 0 ? (
            <List>
              {displayedOperations.map((operation: any) => (
                <View>
                  <Text style={styles.h2}>{operation.title}</Text>
                  <span></span>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      paddingBottom: "5px",
                    }}
                    wrap={false}
                  >
                    <Text style={styles.text}>Status </Text>
                    <Text style={styles.textBold}> {operation.status} </Text>

                    <Text style={styles.text}>{"   "} Work Center </Text>
                    <Text style={styles.textBold}>
                      {" "}
                      {operation.workCenter.buildingSiteName}:{" "}
                      {operation.workCenter.buildingName}:{" "}
                      {operation.workCenter.name}{" "}
                    </Text>
                    <span></span>
                  </View>
                  {operation.whereFound && operation.whereFound.length > 0 && (
                    <View
                      style={{
                        paddingBottom: "5px",
                      }}
                    >
                      <Text style={styles.h3}>NC found on this operation </Text>{" "}
                      {operation.whereFound.map((report: any) => (
                        <>
                          <Link
                            style={styles.link}
                            src={
                              window.location.origin +
                              Routes.nonconformance(report.reportId)
                            }
                          >
                            {report.reportId}
                          </Link>
                        </>
                      ))}
                    </View>
                  )}
                  {operation.skipSettings?.skippability ===
                    Skippability.SKIPPABLE &&
                    operation.skipSettings?.skippingCriteria.length > 0 && (
                      <Segment>
                        <Text style={styles.textBold}>Skip Conditions</Text>
                        {operation.skipSettings.skippingCriteria.map(
                          (criteria: string) => (
                            <Html stylesheet={styles}>
                              {formatJulesContent(criteria)}
                            </Html>
                          )
                        )}
                      </Segment>
                    )}
                  {displayedOperations.length < 20 &&
                    operation.steps.length > 0 &&
                    operation.steps.map(
                      (step: any) =>
                        step.status !== StepStatus.Deleted && (
                          <View>
                            <Text style={styles.h3}>
                              Step {step.displayId}
                              {"    "}
                              {(step.status === StepStatus.Completed ||
                                step.status === StepStatus.Skipped) &&
                                step.status}
                            </Text>
                            {step.instructions ? (
                              <View>
                                <Html stylesheet={styles}>
                                  {formatJulesContent(step.instructions)}
                                </Html>
                                <hr style={styles.lineGrey}></hr>
                              </View>
                            ) : (
                              <Text style={styles.text}>
                                This step does not have instructions
                              </Text>
                            )}
                            {step.attachments && step.attachments.length > 0 && (
                              <View>
                                <Text style={styles.h4}>Attachments2</Text>
                                <Segment>
                                  {step.attachments.map(
                                    ({ fileName, url }: any) => (
                                      <FileAttachment
                                        url={url}
                                        fileName={fileName}
                                      />
                                    )
                                  )}
                                </Segment>
                                <hr style={styles.lineGrey}></hr>
                              </View>
                            )}
                            {step.executionNotes && (
                              <View>
                                <Text style={styles.h4}>Execution Notes</Text>
                                <Html stylesheet={styles}>
                                  {formatJulesContent(step.executionNotes)}
                                </Html>
                              </View>
                            )}
                            {step.executionAttachments &&
                              step.executionAttachments.length > 0 && (
                                <View>
                                  <Text style={styles.h4}>
                                    Execution Attachments
                                  </Text>
                                  <Segment>
                                    {step.executionAttachments.map(
                                      ({ fileName, url }: any) => (
                                        <FileAttachment
                                          url={url}
                                          fileName={fileName}
                                        />
                                      )
                                    )}
                                  </Segment>
                                  <hr style={styles.lineGrey}></hr>
                                </View>
                              )}
                            {step.closeOuts && step.closeOuts.length > 0 && (
                              <>
                                {step.closeOuts.map((closeOut: any) => (
                                  <View>
                                    <Text style={styles.textBold}>
                                      {closeOut.title}
                                    </Text>
                                    <Text style={styles.text}>
                                      {closeOut.description}
                                    </Text>
                                    {closeOut.attachments &&
                                      closeOut.attachments.length > 0 && (
                                        <Segment>
                                          {closeOut.attachments.map(
                                            ({ fileName, url }: any) => (
                                              <FileAttachment
                                                url={url}
                                                fileName={fileName}
                                              />
                                            )
                                          )}
                                        </Segment>
                                      )}
                                  </View>
                                ))}
                                <hr style={styles.lineGrey}></hr>
                              </>
                            )}
                            {step.signOffs && step.signOffs.length > 0 && (
                              <>
                                {step.signOffs.map((signOff: any) => (
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Text style={styles.text}>Sign-off </Text>
                                    <Text style={styles.textBold}>
                                      {" "}
                                      {formatRole(signOff.role)}{" "}
                                    </Text>
                                    <Text style={styles.text}>
                                      {" "}
                                      {moment
                                        .unix(signOff.timestamp)
                                        .format("MM/DD/YYYY") ||
                                        moment
                                          .unix(signOff.date)
                                          .format("MM/DD/YYYY")}
                                    </Text>
                                  </View>
                                ))}
                              </>
                            )}
                            <hr style={styles.lineDarkGrey}></hr>
                          </View>
                        )
                    )}
                  <hr style={styles.lineBlue}></hr>
                </View>
              ))}
            </List>
          ) : (
            <View>
              <Text style={styles.text}>No operations</Text>
              <hr style={styles.lineGrey}></hr>
            </View>
          )}
          {deletedOperations && deletedOperations.length > 0 && (
            <List>
              <Text style={styles.h1}>Deleted Operations</Text>
              {deletedOperations.map((operation: any) => (
                <View>
                  <Text style={styles.h2}>{operation.title}</Text>
                  <span></span>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      paddingBottom: "5px",
                    }}
                    wrap={false}
                  >
                    <Text style={styles.text}>Status </Text>
                    <Text style={styles.textBold}>
                      {" "}
                      {operation.status} {"   "}
                    </Text>

                    {operation.workCenter && (
                      <>
                        <Text style={styles.text}>Work Center </Text>
                        <Text style={styles.textBold}>
                          {" "}
                          {operation.workCenter.buildingSiteName || ""}:{" "}
                          {operation.workCenter.buildingName || ""}:{" "}
                          {operation.workCenter.name || ""}{" "}
                        </Text>
                        <span></span>
                      </>
                    )}
                  </View>
                </View>
              ))}
            </List>
          )}
        </View>
        <View style={styles.footer} fixed>
          <hr style={styles.line}></hr>
          <Text
            style={styles.text}
            render={({ pageNumber }) =>
              `Restricted - Blue Origin Proprietary Information / Subject to Export Control                                ${pageNumber}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};

/**
 * Converts Jules HTML to supported elements in react-pdf.
 * @param content a string containing Jules HTML
 * @returns react-pdf frienldy HTML elements
 */
export const formatJulesContent = (content: string): any => {
  content = content.replace(/<img[^>]*>/g, "");
  content = content.replace(/<a[^>]*>/g, "");
  content = content.replace(/<\/a>/g, "");
  content = content.replace(/<br \/>/g, "");

  return content;
};
