import {
  Segment,
  Text,
  theme,
  SegmentSpacing,
} from "@blueorigin/blue-branding-kit";
import React from "react";
import styled from "styled-components";
import { Skippability, Step, StepStatus } from "../../../models/generated";
import { SignOff } from "../../layout/sign-off";
import { Card, CardContent } from "@material-ui/core";
import { WorkOrderCloseOut } from "./work-order-close-out";
import { JulesContent } from "../../layout/jules-content";
import { Spacer } from "../../layout/spacer";
import { WorkOrderSkipConditions } from "./work-order-skip-conditions";
import { FileAttachment } from "../../FileAttachment";

const H4 = styled.h4`
  font-size: ${theme("Typography.ParagraphFontSize")};
  margin: 0;
`;

const H5 = styled.h5`
  font-size: ${theme("Typography.ParagraphFontSize")};
  margin: 0;
`;

const HR = styled.hr`
  border: 0.5px solid ${theme("Colors.LightGray")};
`;

const Status = styled.strong`
  color: ${theme("Colors.PrimaryGreen")};
`;

export interface WorkOrderStepProps {
  step: Step;
  showStatus?: boolean;
}

export const WorkOrderStep = (props: WorkOrderStepProps) => {
  const { step } = props;
  const {
    status,
    displayId,
    instructions,
    executionNotes,
    closeOuts,
    signOffs,
    skipSettings,
    attachments,
    executionAttachments,
  } = step;

  return (
    <Segment>
      <Card elevation={3}>
        <CardContent>
          <Segment>
            <H4>
              <Spacer>
                <span>Step {displayId}</span>
                {(status === StepStatus.Completed ||
                  status === StepStatus.Skipped) && <Status>{status}</Status>}
              </Spacer>
            </H4>
          </Segment>
          {status === StepStatus.Skipped &&
            skipSettings?.skippability === Skippability.SKIPPABLE &&
            skipSettings?.skippingCriteria.length > 0 && (
              <>
                <Segment>
                  <HR />
                </Segment>

                <Segment>
                  <WorkOrderSkipConditions
                    skippingCriteria={skipSettings.skippingCriteria}
                  />
                </Segment>
                <Segment>
                  <HR />
                </Segment>
              </>
            )}
          <Segment>
            {instructions ? (
              <JulesContent content={instructions} />
            ) : (
              <Text>
                <em>This step does not have instructions</em>
              </Text>
            )}
          </Segment>
          {attachments && attachments.length > 0 && (
            <>
              <Segment>
                <HR />
              </Segment>
              <Segment>
                <H5>Attachments</H5>
              </Segment>
              <Segment>
                {attachments.map(({ fileName, url }) => (
                  <FileAttachment url={url} fileName={fileName} />
                ))}
              </Segment>
            </>
          )}
          {executionNotes && (
            <>
              <Segment>
                <HR />
              </Segment>
              <Segment>
                <H5>Execution Notes</H5>
              </Segment>
              <Segment spacing={SegmentSpacing.LESS}>
                <JulesContent content={executionNotes} />
              </Segment>
            </>
          )}
          {executionAttachments && executionAttachments.length > 0 && (
            <>
              <Segment>
                <HR />
              </Segment>
              <Segment>
                <H5>Execution Attachments</H5>
              </Segment>
              <Segment>
                {executionAttachments.map(({ fileName, url }) => (
                  <FileAttachment fileName={fileName} url={url} />
                ))}
              </Segment>
            </>
          )}
          {closeOuts && closeOuts.length > 0 && (
            <>
              <Segment>
                <HR />
              </Segment>
              <Segment>
                <H5>Closeouts</H5>
              </Segment>
              {closeOuts.map((closeOut) => (
                <WorkOrderCloseOut key={closeOut.key} closeOut={closeOut} />
              ))}
            </>
          )}
          {signOffs.length > 0 && (
            <>
              <Segment>
                <HR />
              </Segment>
              {signOffs.map((signOff, index) => (
                <SignOff key={index} signOff={signOff} />
              ))}
            </>
          )}
        </CardContent>
      </Card>
    </Segment>
  );
};
